import { useState, useEffect } from "react";
import "../styles/genericPage.css";
import EmploymentRateLine from "charts/workforce/EmploymentRateLine";



function Workforce () {

    const [workforceData, setWorkforceData] = useState([]);

    useEffect(() => {
        setWorkforceData([{
            title: "Employment rate across 3 census tracts served by the WRK Group",
            indicator: "-2.7% per year",
            value: "Change in employment rate in WRK Group’s census tracts compared to Wilmington since 2017",
            notes: ["In the 3 census tracts served by the WRK group, the gap in employment rate to the Wilmington’s employment rate have been improving on average by 0.2% per year from 2011 to 2020.", 
            "However, the gaps are worsening since 2017 by -2.7% per year."],
            chart: <EmploymentRateLine/>
        }])
    }, []); 

    return (
        <>
     
       {workforceData.map((item) => (
        <div className="flex" key={item.title}>
          <div className="chart-container">
            <div className="header">
              <h2 className="m-0 big-font">{item.title}</h2>
            </div>
            <div className="gap-class">
              {item.chart}
            </div>
          </div>
          {item.value ? (
          <div className="notes-container">
            <div className="value-box gap-class">
              <h3 className="big-font">{item.indicator}</h3>
              <h4>{item.value}</h4>
            </div>
          
            {item.notes ? (
            <div className="notes gap-class">
              <h3 className="text-center big-font">Notes</h3>
              <hr></hr>
              <ul>
                {item.notes.map((note, i) => (
                  <li key={i}>
                    <h4>{note}</h4>
                    <br></br>
                  </li>
                ))}
              </ul> 
            </div>) : ("")}
          </div>) : ("")}
        </div>
      ))}
      </>
    )
}

export default Workforce;