import { csv, autoType } from "d3";
import { useState, useEffect } from "react";
import { Chart } from "../templates/Chart";
import { tidy, complete, groupBy, summarize, mean } from "@tidyjs/tidy";
import data from "data/events/events_summary.csv";

const locations =  ["Coliseum","Digital Media Arts Room","Resume","The Warehouse","Virtual Classes","Other"];
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const caption = "Source: <a href='https://thewarehouse.recdesk.com/Community/Calendar' target='_blank'>The Warehouse Calendar</a>";


export const WarehouseEventsStackedColumn = function ({ year, title, tooltipOptions }) {

  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    csv(data, autoType).then((d) => {
      setChartData(d.sort((a, b) => a.month - b.month));
    });
  }, []);  
  
  const completedData = tidy(chartData, complete(["year", "month", "location"], { n: 0 }));

  const series = locations.map((location, i) => {
    return {
      type: "column",
      name: location,
      data: completedData
        .filter((c, i) => c.location === location && c.year === year)
        .map((c, i) => {
          return {
            y: c.n,
            location: c.location,
            month_abb: c.month_abb,
            events_per_month: c.events_per_month,
            prop_per_month: c.prop_per_month,
            pct_per_month: c.pct_per_month,
            month: c.month,
            year: c.year
          };
        }),
    };
  });

  const options = {
    title: {
      text: title,
    },
    caption: {
      text: caption,
      useHTML: true,
    },
    tooltip: {
      useHTML: true,
      formatter: tooltipOptions,
    },
    plotOptions: {
      column: {
        stacking: "normal",
      }
    },
    series: series,
    legend: {
      enabled: true,
      verticalAlign: "top",
      itemMarginTop: 10,
      itemMarginBottom: 10,
    },
    xAxis: {
      categories: months,
    },
    yAxis: {
      min: 0,
      max: 180,
      title: {
        text: undefined,
      },
    },
  };

  return (
    <div>
      {chartData === undefined ? (
        "Loading"
      ) : (
        <Chart options={{ ...options }} />
      )}
    </div>
  );
};

export const AvgWarehouseEventsStackedColumn = function ({ title, tooltipOptions }) {
  
  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    csv(data, autoType).then((d) => {
      setChartData(d.sort((a, b) => a.month - b.month));
    });
  }, []);  

  const avgData = tidy(
    chartData,
    complete(["year", "month_abb", "location"], { n: 0 }),
    groupBy(['location', 'month_abb'], 
    [summarize({ n: mean("n") })])
    );

  // const eventsPerMonth = tidy(chartData, groupBy(['month_abb'], [summarize({ eventsPerMonth: sum("events_per_month")})] )).map(d => d.eventsPerMonth);

  const series = locations.map((location, i) => {
    return {
      type: "column",
      name: location,
      data: avgData
        .filter((c, i) => c.location === location)
        .map((c, i) => {
          return {
            y: c.n,
            location: c.location,
            month_abb: c.month_abb
          }
        }),
    };
  });

  const options = {
    title: {
      text: title,
    },
    caption: {
      text: caption,
      useHTML: true,
    },
    tooltip: {
      useHTML: true,
      formatter: tooltipOptions,
    },
    plotOptions: {
      column: {
        stacking: "normal",
      }
    },
    series: series,
    legend: {
      verticalAlign: "top",
      itemMarginTop: 10,
      itemMarginBottom: 10
    },
    xAxis: {
      categories: months,
    },
    yAxis: {
      min: 0,
      max: 180,
      title: {
        text: undefined,
      }
    }
  };

  return (
    <div>
      {chartData === undefined ? (
        "Loading"
      ) : (
        <Chart options={{ ...options }} />
      )}
    </div>
  );
};