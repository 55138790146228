import data from "data/education/graduation_gaps.csv";
import { Dumbbell } from 'charts/templates/Dumbbell';

export default function GraduationGap() {

  const tooltipOptions = function() {

    const tooltip = 
      this.series.name === "Delaware" 
      ? `In ${this.x}, <strong>${this.y.toFixed(1)}%</strong> of ${this.series.name} high school students <br/> graduated within 4 years.`
      : `In ${this.x}, <strong  style="color: var(--green)">${this.y.toFixed(1)}%</strong> of students in ${this.series.name} <br/> graduated within 4 years. The gap to the Delaware average was <strong>${this.point.gap.toFixed(1)}%</strong>.`

      
      return tooltip; 
        }

    const caption = "Source: <a href='https://data.delaware.gov/Education/Student-Graduation/t7e6-zcnn' target='_blank'>Delaware Open Data</a>";


  return (
  <>


 <Dumbbell 
      data={data} 
      seriesOne={{column:"brandywine_christina_colonial", name: "Brandywine, Christina, and Colonial Districts"}}
      seriesTwo={{column:"delaware", name: "Delaware"}}
      xAxisColumn = "schoolyear"
      gapColumn = "gap"
      tooltipOptions = {tooltipOptions}
      title="High School Students Graduating Within 4 Years" 
      caption = {caption} />  

  </>

 
  )
}