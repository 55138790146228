import { Column } from "charts/templates/Column";
import { Line } from "charts/templates/Line";
import data from "data/housing/riverside_total_units.csv"
import { useState } from "react";

export default function AvailableUnits() {

	const [filters, setFilters] = useState({
		chart: "Available Units"
	});

	const toggleCharts = (event) => {
		setFilters({ ...filters, chart: event.target.value })
	}

	const caption = "Source: <a href='https://www.huduser.gov/portal/datasets/assthsg.html' target='_blank'>HUD</a>";

	const tooltipOptionsForLine = function () {
		return `In ${this.x}, the number of available units in Riverside changed ${this.y.toFixed(1)}% from previous year.`;
	}

	const tooltipOptionsForColumn = function () {
		return `In ${this.x}, Riverside had ${this.y} public housing units.`;
	}

	const moreOptionsForColumn = {
		yAxis: {
			title: { text: undefined },
		},
	};

	const moreOptionsForLine = {
		yAxis: {
			labels: {
				formatter: function () {
					return (this.value) + "%";
				}
			},
			title: { text: undefined },
		},
	};

	return (
		<div className="flex" >
			<div className="chart-container">
				<div className="container">
					<div className="button-container m-0">
						<button className={"toggle-button " + (filters.chart === "Available Units" ? "active" : "not-active")} value="Available Units" onClick={(event) => toggleCharts(event)}>Amount of Units</button>
						<button className={"toggle-button " + (filters.chart === "Percentage Changes" ? "active" : "not-active")} value="Percentage Changes" onClick={(event) => toggleCharts(event)}>Percent Change</button>
					</div>
				</div>
				<div className="gap-class">
					<div className="container">
						{filters.chart === "Available Units" ?
							<Column
								data={data}
								column="total_units"
								xAxisColumn="year"
								columnColor="var(--green)"
								title="Available Units For Rent in Riverside"
								caption={caption}
								seriesName="Riverside"
								tooltipOptions={tooltipOptionsForColumn}
								moreOptions={moreOptionsForColumn}
							/>
							: <Line
								data={data}
								title="Percentage Change In Available Units"
								caption={caption}
								columns={[
									{ colName: "pct_change", name: "Riverside", color: "var(--green)" },
								]}
								xAxisColumn="year"
								tooltipOptions={tooltipOptionsForLine}
								moreOptions={moreOptionsForLine}
							/>
						}

					</div>
				</div>
			</div>
		</div>
	)
}

