import CommunitySafetyRating from "charts/safety/CommunitySafetyRating";
import { SafetyStackedPercentColumn } from "charts/safety/SafetyStackedPercentColumn";
import {SafetyConcernsBar} from "charts/safety/SafetyConcernsBar";
import {useState,useEffect} from 'react';
import {useParams} from 'react-router-dom'; 
import "styles/genericPage.css";

function Safety () {

   const [safetyData, setSafetyData] = useState([]);
   const { indicator } = useParams();

  useEffect(() => {
    switch (indicator) {

        case "concerns":
        setSafetyData([
        {
            title: "Top Safety Concerns in Wilmington",
            notes: null,
            chart: <SafetyConcernsBar />

        },
        ]);
        break;

        
        case "rating":
        setSafetyData([
        {
            title: "Overall Rating of Safety in Wilmington",
            indicator: ["18%"],
            value: ["survey participants reported the safety of the community as \"good\" or \"very good\""],
            notes: null,
            chart: <SafetyStackedPercentColumn />

        },
        ]);
        break;

        case "walking":
        setSafetyData([
        {
            title: "Safety Ratings: Day vs. Night",
            indicator: ["66.8%", "32.8%"],
            value: ["survey participants reported feeling safe while walking during the day", "survey participants reported feeling safe while walking at night"],
            notes: null,
            chart: <CommunitySafetyRating />

        },
        ]);
        break;

        default:
            setSafetyData([{
              title: "Top Safety Concerns in Wilmington",
              notes: null,
              chart: <SafetyConcernsBar />
          }]);
            break;
    }}, [indicator]); 


    return (
        <>
       {safetyData.map((item) => (
        
        <div className="flex" key={item.title}>
          <div className="chart-container">
            <div className="header">
              <h2 className="m-0 big-font">{item.title}</h2>
            </div>
            <div className="gap-class">
              {item.chart}
            </div>
          </div>
          {item.value ? (

           

             <div className="notes-container">
                { item.indicator.map((indicator, i) => (
            <div className="value-box gap-class" key={i}>
              <h3 className="big-font">{indicator}</h3>
              <h4>{item.value[i]}</h4>
            </div>

                )) }
            
          
            {item.notes ? (
            <div className="notes gap-class">
              <h3 className="text-center big-font">Notes</h3>
              <hr></hr>
              <ul>
                {item.notes.map((note, i) => (
                  <li key={i}>
                    <h4>{note}</h4>
                    <br></br>
                  </li>
                ))}
              </ul> 
            </div>) : ("")}
          </div>) : ("")}
        </div>
      ))}
        </>
    )
}

export default Safety;