import { useState } from "react";
import "styles/genericPage.css";
import "styles/education-achievement.css";
import HousingDetailsChart from "charts/housing/HousingDetailsChart";
import { outcomeName } from "data/housingDetailData";

function Button(props) {
	return (
		<button className={"toggle-button " + (props.filters.contentarea.includes(props.value) ? "active" : "not-active")} value={props.value} onClick={(event) => props.toggleCharts(event)}>{props.value}</button>
	)
}

export default function HousingDetails() {

	const toggleGroup = ["3 Tracts", "Delaware", "Eastlake/Eastlawn", "Northeast", "Riverside"];

	const chartColors = {
		"3 Tracts" :"var(--yellow)",
		"Delaware" : "#c20022",
		"Eastlake/Eastlawn" : "var(--blue)", 
		"Northeast" : "var(--orange)", 
		"Riverside" : "var(--green)"
	}

	const chartID = {
		"3 Tracts" : null,
		"Delaware" : null,
		"Eastlake/Eastlawn" : 10003000601, 
		"Northeast" : 10003000602, 
		"Riverside" : 10003003002
	}

	const [filters, setFilters] = useState({
		contentarea: ["3 Tracts"],
		method: "Average",
		outcome: "total_units"
	});

	const toggleMethod = (event) => {
		setFilters({ ...filters, method: event.target.value })
	}

	const toggleOutcome = (event) => {
		setFilters({ ...filters, outcome: event.target.value })
	}

	const toggleCharts = (event) => {
		const subjectsCopy = [...filters.contentarea]

		if (!subjectsCopy.includes(event.target.value)) {
			subjectsCopy.push(event.target.value)
			subjectsCopy.sort((a, b) => a.localeCompare(b))
			setFilters({ ...filters, contentarea: subjectsCopy })
		}
		else {
			const filteredSubjects = subjectsCopy.filter(item => item !== event.target.value)
			filteredSubjects.sort((a, b) => a.localeCompare(b))
			setFilters({ ...filters, contentarea: filteredSubjects })
		}
	}

	return (
		<>
			<div className="d-flex">
				<div className="header-container container">
					<h2 className="big-font">Housing Details</h2>
					<div className="interaction-container container">
						<div className="buttons-container">
							{toggleGroup.map(e => {
								return (
									<Button
										key={e}
										value={e}
										toggleCharts={toggleCharts}
										filters={filters}
									/>
								)
							})}

						</div>						
					</div>
					<div className="interaction-container container">
						<div className="dropdown-container">
							<label className="label" htmlFor="outcome">
								Choose an outcome:
							</label>
							<div className="select">
								<select
									id="outcome"
									onChange={(event) => toggleOutcome(event)}
								>
									{Object.keys(outcomeName).map(e => {
										return (
											<option value={e} key={e} >{outcomeName[e]}</option>
										)
									})}
									
								</select>
							</div>
						</div>
						<div className="dropdown-container">
							<label className="label" htmlFor="method">
								Choose a method:
							</label>
							<div className="select">
								<select
									id="method"
									onChange={(event) => toggleMethod(event)}
								>
									<option value="Average">Average</option>
									<option value="Sum">Sum</option>
								</select>
							</div>
						</div>						
					</div>
				</div>
			</div>
			<div className="container">
				{filters.contentarea.map(e => {
					return (
						<div className="gap-class" key={e} >
							<HousingDetailsChart
								tract={{ name: e, code: chartID[e] }}
								method={filters.method}
								interestVar={{ name: outcomeName[filters.outcome], variable: filters.outcome }}
								color={chartColors[e]}
							/>
						</div>
					)
				})}
			</div>
		</>
	);
}