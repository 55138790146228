import {WarehouseEventsStackedColumn} from "./WarehouseEventsOverTime"

export const WarehouseEventsYearly = function ({year}) {

const tooltipOptions = function () {
    const { y, location, month_abb, events_per_month, pct_per_month, year } = this.point;
    const tooltip = `In ${month_abb} ${year}, ${pct_per_month} of all events were held at <strong style = "color:${this.color} !important">${location}</strong> (${y}/${events_per_month} events)`;

    return tooltip;
  };

// const averageTooltipOptions = function () {

//     const { y, location, month_abb } = this.point;
//     const tooltip = `From 2020 to 2022, an average of ${y.toFixed(0)} events were held at <strong style = "color:${this.color} !important">${location}</strong> in ${month_abb}.`;

//     return tooltip;
//   };

  return (
    <>

<WarehouseEventsStackedColumn
        year={year}
        title={"Average Warehouse Events in " + year}
        tooltipOptions={tooltipOptions}
      />
    </>
  );
};