import { Line } from "charts/templates/Line";
import data from "data/housing/months_from_movein.csv";

export default function MovedIn () {

    const caption = "Source: <a href='https://www.huduser.gov/portal/datasets/assthsg.html' target='_blank'>HUD</a>";

    const tooltipOptions = function() {
        return  `In ${this.x}, the average family using public housing in ${this.series.name} stayed for ${this.y.toFixed(0)} months.`;
    }
    
    const moreOptions = {
        yAxis: {
            labels: {
              formatter: function () {
                return (this.value) + " mo.";
              }
            },
            title: {text: undefined},
          },
    }

    return (
        <Line
            data={data}
            title="Average Months Since Moved In"
            caption={caption}
            columns={[
                { colName: "DE", name: "Delaware", color: "var(--gray)" },
                { colName: "riverside", name: "Riverside", color: "var(--green)" },
            ]}
            xAxisColumn="year"
            tooltipOptions={tooltipOptions}
            moreOptions={moreOptions}
        />
    )
}