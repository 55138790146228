
import { indicatorData } from "data/indicatorData";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "styles/genericPage.css";

export default function Housing () {

  const [housingData, setHousingData] = useState({});
  const { indicator } = useParams();

  useEffect(() => {
    switch (indicator) {

        case "rent":
          setHousingData(indicatorData[0].content[0]);
          break;

        case "occupied":
          setHousingData(indicatorData[0].content[1]);
          break;

        case "moved-in":
          setHousingData(indicatorData[0].content[2]);
          break;

        case "housing-details":
          setHousingData(null);
          break;

        default:
          setHousingData(indicatorData[0].content[0])
          break;
    }
  }, [indicator]);

  return (
    <>
      {housingData ? 
        <div className="flex" key={housingData.statsID}>
          <div className="chart-container">
            <div className="header">
              <h2 className="m-0 big-font">{housingData.title}</h2>
            </div>
            <div className="gap-class">
              {housingData.chart}
            </div>
          </div>
          <div className="notes-container">
            <div className="value-box gap-class">
              <h3 className="big-font">{housingData.statsValue + " " + housingData.statsUnit}</h3>
              <h4>{housingData.statsCaption}</h4>
            </div>
          </div>
        </div>
      : null}
    </>
  );
}
