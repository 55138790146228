import { csv, autoType } from 'd3';
import { useState, useEffect } from 'react';
import { Chart } from './Chart';


export const Line = function({data, columns, xAxisColumn, title, caption, yAxisLabel, tooltipOptions, moreOptions, moreCols}) { 

    const [chartData, setChartData] = useState([]); 
    useEffect(() => { csv(data, autoType)
                        .then(d => { setChartData(d) }) }, [data]); 

   const tooltipCols = [];         
   
   if (moreCols) {
     chartData.forEach((row) => {
       const yearCols = {};
       moreCols.forEach((column) => {
         yearCols[column] = row[column];
       });
       tooltipCols.push(yearCols);
     });
   }


    const series = columns.map((item, i) => { 
        return {
            type: "line",
            color: item.color,
            name: item.name,
           data: chartData.map((c, i) =>{ return { y: +c[item.colName], ...tooltipCols[i] }}),
            marker: { 
                symbol: "circle",
                radius: 3
            }, 
            dashStyle: item.dashed ? "Dash" : "Solid",
            states: { 
                hover: {
                    enabled: false
                },
                inactive: {
                    enabled: false
                }
            }
        }
    })                   

    const options = {
        title: { 
            text: title
        },
        caption: { 
            text: caption,
            useHTML: true
        }, 
        series: series,
        tooltip: {
            formatter: tooltipOptions,
          },
        xAxis: { 
            categories: chartData.map(item => item[xAxisColumn]),
            labels: { 
               step: title === "Total event hours per week" ? 12 : 0, // suboptimal fix to prevent all dates from appearing on x-axis of event hours chart
                formatter: function() { // strip days from x-axis in event hours chart 
                    const pattern = /\s[0-9]{1,2},/;
                    const label = title === "Total event hours per week" ?  this.value.replace(pattern,"") : this.value;  
                    return label; 
                }
        }
    },
        yAxis: {
            title: { 
                text: yAxisLabel
            },
        }
      }

      
      return (
        <div>
    {
    chartData === undefined
       ? "Loading" 
       : <Chart options = {{...options, ...moreOptions}}/>
    }
      </div>
    )


}