import { Line } from "../templates/Line";
import data from "data/workforce/employed_wide.csv";

export default function EmploymentRateLine() { 

  const tooltipOptions = function() { 
    return `In ${this.series.name}, ${(this.y * 100).toFixed(1)}% of workers were employed in ${this.key}.`
   }

   const caption =
    "Source: <a href='https://www.census.gov/' target='_blank'>Census 5-Year ACS</a>";

    const moreOptions = {
        yAxis: {
          labels: {
            formatter: function () {
              return (this.value * 100).toFixed(0) + "%";
            },
          },
          title: {text: undefined}
        },
      };

    return ( 
        <>
        <Line
            data = {data}
            columns = {[
                {name: "Riverside", colName: "Riverside"},
                {name: "Eastlake", colName: "Eastlake"},
                {name: "Northeast", colName: "Northeast"},
                {name: "Wilmington", colName: "Wilmington", color: "gray", dashed: true}
            ]}
            xAxisColumn = "year"
            title = "Employment rate across 3 census tracts served by the WRK Group"
            caption = {caption}
            tooltipOptions={tooltipOptions}
            moreOptions = {moreOptions}/>
        </>
    )
}