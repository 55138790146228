import { Line } from "charts/templates/Line";
import data from "data/events/events_weekly_duration.csv";

export function EventHoursPerWeek() {
  const tooltipOptions = function () {
    return `In the week of ${this.x}, The Warehouse provided ${this.y} hours of events.`;
  };

  const caption =
    "Source: <a href='https://thewarehouse.recdesk.com/Community/Calendar' target='_blank'>The Warehouse Calendar</a>";

  const moreOptions = {
    legend: { enabled: false },
  };

  return (
    <>
      <Line
        data={data}
        columns={[{ colName: "weekly_sum", color: "var(--green)" }]}
        xAxisColumn="week_label"
        title="Total event hours per week"
        caption={caption}
        tooltipOptions={tooltipOptions}
        moreOptions={moreOptions}
      />
    </>
  );
}

// week,weekly_sum,year,week_label,hovertext
