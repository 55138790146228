import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import HC_more from 'highcharts/highcharts-more';
import HC_exporting from 'highcharts/modules/exporting'
import HC_noData from 'highcharts/modules/no-data-to-display'
import highchartsAccessibility from 'highcharts/modules/accessibility'

HC_more(Highcharts);
HC_exporting(Highcharts);
HC_noData(Highcharts);
highchartsAccessibility(Highcharts);

export const Chart = function({data, options}) { 

    options  = {...options, 
        colors: options.colors ? options.colors : ["var(--primary-color)", "var(--green)", "var(--orange)", "var(--blue)", "var(--yellow)", "#c20022", "var(--light-blue)"],
        chart: {
            height: (9 / 16 * 100) + '%', // 16:9 ratio,
            style: {
                fontFamily: 'Oswald'
            }, 
            inverted: options.inverted,
            animation: { duration:300 }
        }, 
        legend: options.legend ? {...options.legend, itemStyle: {fontSize: ".8rem"}} : {itemStyle: {fontSize: ".9rem"}, itemDistance: 40},
        tooltip: {...options.tooltip, style: { fontSize: "1rem" }},
        xAxis: options.xAxis ? { ...options.xAxis, labels: { ...options.xAxis.labels, style: { fontSize: ".9rem" } }} : {labels: { style: { fontSize: ".8rem" } }}, 
        yAxis: options.yAxis ? { ...options.yAxis, labels: { ...options.yAxis.labels, style: { fontSize: ".9rem" } }} : {labels: { style: { fontSize: ".8rem" } }},
        plotOptions: options.plotOptions ?{ ...options.plotOptions, series: { showInLegend: options.series.length > 1 ? true : false }} : {series: { showInLegend: options.series.length > 1 ? true : false }},
        credits: {
            enabled: false
        },
        noData: {
            style: {fontSize: "2rem" }
        }
    }; 

    return (
        <>
        {
            <HighchartsReact highcharts={Highcharts} options={options}/>
        }
        
        </>
    )
}

