import { AvgWarehouseEvents } from "charts/events/AvgWarehouseEvents";
import { WarehouseEventsYearly } from "charts/events/WarehouseEventsYearly";
import { useState } from 'react'; 

function EventCounts() {

    const [eventCountData, setEventCountData] = useState([
      {title: "Warehouse Events: 3-Year Average", indicator: "86 events",
      value: "Average events per month from 2020-2022 across all Warehouse venues",
      notes: null, chart: <AvgWarehouseEvents/>}
    ]);

      const [button, setButton] = useState("3-Year Average");

      const toggleCharts = (event) => {
        switch (event.target.value) {
          case "2020":
            setEventCountData([
              {title: "Warehouse Events in 2020", indicator: "62 events",
              value: "Average events per month in 2020 across all Warehouse venues",
              notes: null, chart: <WarehouseEventsYearly year={2020} />}
            ])
            setButton("2020");
            break;
            
          case "2021":
            setEventCountData([
              {title: "Warehouse Events in 2021", indicator: "92 events",
              value: "Average events per month in 2021 across all Warehouse venues",
              notes: null, chart: <WarehouseEventsYearly year={2021} />}
            ])
            setButton("2021");
            break;

          case "2022":
            setEventCountData([
              {title: "Warehouse Events in 2022", indicator: "102 events",
              value: "Average events per month in 2022 across all Warehouse venues",
              notes: null, chart: <WarehouseEventsYearly year={2022} />}
            ])
            setButton("2022");
            break;

          case "3-Year Average":
            setEventCountData([
              {title: "Warehouse Events: 3-Year Average", indicator: "86 events",
              value: "Average events per month from 2020-2022 across all Warehouse venues",
              notes: null, chart: <AvgWarehouseEvents/>}
            ])
            setButton("3-Year Average");
            break;

          default:
            setEventCountData([
              {title: "Warehouse Events: 3-Year Average", indicator: "86 events",
              value: "Average events per month from 2020-2022 across all Warehouse venues",
              notes: null, chart: <AvgWarehouseEvents/>}
            ])
            setButton("3-Year Average");
            break;
        }
      };

      return (
        <>
          {eventCountData.map((item) => (
            <div className="flex" key={item.title}>
              <div className="chart-container">
                <div className="header">
                  <h2 className="big-font">{item.title}</h2>
                  <div className="button-container container">
                    <button className={"toggle-button " + (button === "3-Year Average" ? "active" : "not-active")} value="3-Year Average" onClick={(event) => toggleCharts(event)}>
                      3-Year Average
                    </button>
                    <button className={"toggle-button " + (button === "2022" ? "active" : "not-active")} value="2022" onClick={(event) => toggleCharts(event)}>
                      2022
                    </button>
                    <button className={"toggle-button " + (button === "2021" ? "active" : "not-active")} value="2021" onClick={(event) => toggleCharts(event)}>
                      2021
                    </button>
                    <button className={"toggle-button " + (button === "2020" ? "active" : "not-active")} value="2020" onClick={(event) => toggleCharts(event)}>
                      2020
                    </button>
                  </div>
                </div>
                <div className="gap-class">{item.chart}</div>
              </div>
              {item.value ? (
                <div className="notes-container">
                  <div className="value-box gap-class">
                    <h3 className="big-font">{item.indicator}</h3>
                    <h4>{item.value}</h4>
                  </div>
    
                  {item.notes ? (
                    <div className="notes gap-class">
                      <h3 className="text-center big-font">Notes</h3>
                      <hr></hr>
                      <ul>
                        {item.notes.map((note, i) => (
                          <li key={i}>
                            <h4>{note}</h4>
                            <br></br>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          ))}
        </>
      );
    }

    export default EventCounts; 