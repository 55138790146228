import data from "data/education/eastside_ELA.csv";
import { Dumbbell } from 'charts/templates/Dumbbell';

export default function LiteracyAchievement() {

  const tooltipOptions = function() {

    const tooltip = 
      this.series.name === "Delaware" 
      ? `In ${this.x}, <strong>${this.y.toFixed(1)}%</strong> of ${this.series.name} students <br/> achieved literacy proficiency.`
      : `In ${this.x}, <strong  style="color: var(--green)">${this.y.toFixed(1)}%</strong> of ${this.series.name} students  <br/> achieved literacy proficiency. The gap to the Delaware average was <strong>${this.point.gap.toFixed(1)}%</strong>.`

      
      return tooltip; 
        }

    const caption = "Source: <a href='https://data.delaware.gov/Education/Student-Assessment-Performance/ms6b-mt82' target='_blank'>Delaware Open Data</a>";


  return (
  

<>


  <Dumbbell 
      data={data} 
      seriesOne={{column:"east_side", name: "East Side Charter"}}
      seriesTwo={{column:"delaware", name: "Delaware"}}
      xAxisColumn = "schoolyear"
      gapColumn = "gap"
      tooltipOptions = {tooltipOptions}
      title="Children Achieving Literacy Proficiency (3rd-8th graders)" 
      caption = {caption} />  
      
  </> 


 
  )
}


