import { Chart } from "charts/templates/Chart";
import { useState, useEffect } from "react";
import data from '../../data/education/graduation_rates.csv'; 
import {csv,autoType} from 'd3'; 
import {tidy, pivotLonger} from '@tidyjs/tidy';


export default function GraduationRate({filters}) {
  
  const [chartData, setChartData] = useState([]);

    useEffect(() => {
      csv(data, autoType).then((d) => {
        setChartData(d);
      });
    }, []);
    
    const columns = [
      { colName: "Brandywine School District", color: "#f8766d" },
      { colName: "Christina School District", color: "#7cae00" },
      { colName: "Colonial School District", color: "#05bfc4" },
      { colName: "State of Delaware", color: "#c77cff" },
    ]

    const tidyChartData = 
      tidy(
        chartData,
        pivotLonger({
          cols: columns.map(col => col.colName),
          namesTo: 'district',
          valuesTo: 'value',
        })
      );
    

  return (
    <>
    {
      filters.map((ratetype, index) => {

    
       const series = columns.map((column, i, arr) => {
         return {
           type: "line",
           name: column.colName,
           color: column.color,
           data: tidyChartData.filter(i => i.district===column.colName && i.ratetype === ratetype).map(d => d.value)
         }
       })


       const options = {
         title: { text: `${ratetype} High School Graduation Rate` },
         tooltip: {
          valueSuffix: "%",
          valueDecimals: 1
        },
         caption: { 
          text: "Source: <a href='https://data.delaware.gov/Education/Student-Graduation/t7e6-zcnn' target='_blank'>Delaware Open Data</a>",
          useHTML: true
        },
         yAxis: {
           labels: {
             formatter: function () {
               return (this.value) + "%";
             }
           },
           title: {text: undefined},
         },
         series: series,
         xAxis: { categories: [2015, 2016, 2017, 2018, 2019, 2020]}
       }

      return  <Chart options={options} filters={filters} key={index}/>
      
     })
   }
    </>
  );
}
