import { Chart } from "../templates/Chart";
import { csv, autoType } from "d3";
import { useState, useEffect } from "react";
import data from 'data/safety/safety_daynight_combined.csv';

export const CommunitySafetyRating = function () {
    const [chartData, setChartData] = useState([]);
    useEffect(() => {
      csv(data, autoType).then((d) => {
        setChartData(d);
      });
    }, []);

    const columns = Array.from(new Set(chartData.map(item => item.response)));
    
    const series = 
    columns.map(column => { 
        return {
            type: "bar",
            name: column,
            data: chartData.filter(d => d.response === column).map(d => d.n),
            dataLabels: {
              enabled: true,
          align: "center",
          useHTML: true,
          style: {  fontSize: "1rem", color:"white", textAlign: "center" }, 
                formatter: function() {
                  return `${this.series.name.replace(" ","<br/>")}: <br/> ${((this.percentage)).toFixed(1)}%`
                }
              }
        }
    })

  
    
    const options = {
      inverted: true,
      title: {
        text: "How safe would you say you feel walking in the community? ",
        style: { fontSize: "1.5rem" }
      },
      caption: {
        text: "Source: 2020 Resident Survey",
        useHTML: true,
      },
      legend: {enabled: false},
      tooltip: {
        formatter: function() { 
            return `${((this.percentage)).toFixed(1)}% of participants reported feeling ${this.series.name.toLowerCase()} ${this.key.toLowerCase()}.`
        }
      },
      series: series,
      xAxis: {
        labels: {
            style: { fontSize: "1rem" }
        },
        categories: ["During the Day", "At Night"]
      },
      yAxis: {
        visible: false,
        reversed: true, 
        reversedStacks: true
      },
      plotOptions: {
        bar: {
            stacking: "percent"
        }
    },
    colors: ["#0C8CCD","#105686","#0C5E5E","#00A454"]
    };


  
    return (
      <div>
        {chartData === undefined ? (
          <strong>Loading</strong>
        ) : (
          <Chart options={{ ...options }} />
        )}
      </div>
    );
  };

  export default CommunitySafetyRating; 