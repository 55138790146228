import "styles/homeBoard.css";
import { indicatorData } from "data/indicatorData";
import { Link } from "react-router-dom";
import React from "react";

function Icon (props) {
    return (
        <div>{props.item.icon}</div>
    )
}

function IndicatorRight (props) {
    return (
        <div className="indicator-right"><Icon {...props}  /></div>
    )
}


function IndicatorLeft (props) {
    return (
        <div>{props.item.title}</div>
    )
}



function IndicatorHeader (props) {
    return (
        <div className="indicator-header">
            <IndicatorLeft {...props} />
            <IndicatorRight {...props} />
        </div>
    )
}

function IndicatorInnerBox (props) {
    const innerBoxStyle = {
        width: props.width,
        height: props.height,
        textDecoration: "none"
    }

    const statsValueStyle = {
        color: props.item.statsValueColor === 1 ? "#5aae61" : (props.item.statsValueColor === 0 ? "#f46d43" : "#1F89C5")
    }

    return (
            <Link to={props.item.statsURL} className="indicator-inner-box" style={innerBoxStyle}>
                <div className="stats-value" style={statsValueStyle}>
                    {props.item.statsValue}
                    <span>{props.item.statsUnit}</span>
                </div>
                <div className="stats-caption">{props.item.statsCaption}</div>
            </Link>
    )
}

function IndicatorMain (props) {
    return (
        <div className="indicator-main">
            {props.item.content.length === 3 
            ?   <React.Fragment>
                    <div className="section-left">
                        {props.item.content.filter(e => e.statsID < 2).map(e => {
                            return (
                                <React.Fragment key={e.statsID}>
                                    <IndicatorInnerBox 
                                        item={e} 
                                        height="140px"
                                        width="100%"
                                    />
                                </React.Fragment>
                            )
                        })}
                    </div>
                    <div className="section-right">
                        {props.item.content.filter(e => e.statsID >= 2).map(e => {
                            return (
                                <React.Fragment key={e.statsID}>
                                    <IndicatorInnerBox 
                                        item={e} 
                                        width="100%"
                                        height="300px"
                                    />
                                </React.Fragment>
                            )
                        })}
                    </div>
                </React.Fragment>
                
            : null}

            {props.item.content.length === 4 
            ?   <React.Fragment>
                    <div className="section-left">
                        {props.item.content.filter(e => e.statsID < 2).map(e => {
                            return (
                                <React.Fragment key={e.statsID}>
                                    <IndicatorInnerBox 
                                        item={e} 
                                        height="140px"
                                        width="100%"
                                    />
                                </React.Fragment>
                            )
                        })}
                    </div>
                    <div className="section-right">
                        {props.item.content.filter(e => e.statsID >= 2).map(e => {
                            return (
                                <React.Fragment key={e.statsID}>
                                    <IndicatorInnerBox 
                                        item={e} 
                                        height="140px"
                                        width="100%"
                                    />
                                </React.Fragment>
                            )
                        })}
                    </div>
                </React.Fragment>
                
            : null}


            {props.item.content.length === 1 
            ?   <React.Fragment>
                    <div className="section-right">
                        {props.item.content.filter(e => e.statsID < 1).map(e => {
                            return (
                                <React.Fragment key={e.statsID}>
                                    <IndicatorInnerBox 
                                        item={e}   
                                        height="220px"  
                                        width="100%"                                    
                                    />
                                </React.Fragment>
                            )
                        })}
                    </div>
                </React.Fragment>
                
            : null}

            {props.item.content.length === 2
            ?   <React.Fragment>
                    <div className="section-left">
                        {props.item.content.filter(e => e.statsID < 1).map(e => {
                            return (
                                <React.Fragment key={e.statsID}>
                                    <IndicatorInnerBox 
                                        item={e} 
                                        height="220px"
                                        width="100%"
                                    />
                                </React.Fragment>
                            )
                        })}
                    </div>
                    <div className="section-right">
                        {props.item.content.filter(e => e.statsID >= 1).map(e => {
                            return (
                                <React.Fragment key={e.statsID}>
                                    <IndicatorInnerBox 
                                        item={e} 
                                        height="220px"
                                        width="100%"
                                    />
                                </React.Fragment>
                            )
                        })}
                    </div>
                </React.Fragment>
                
            : null}

            
        </div>
    )
}


function Indicator (props) {
    const indicatorStyle = {
        width: props.width,
        height: props.height
    }

    return (
        <div className="indicator" id={props.item.title}  style={indicatorStyle} >
            <IndicatorHeader {...props} />
            <IndicatorMain {...props} />
        </div>
    )
}

function HomeBoard () {
    return (
        <div className="home-board">
            <div className="top-level">
                {indicatorData.filter(e => e.id < 2).map(e => {
                    return (
                        <React.Fragment key={e.id}>
                            <Indicator item={e} />
                        </React.Fragment>
                    )
                })}
            </div>
            <div className="bottom-level">
                {indicatorData.filter(e => e.id >= 2).map(e => {
                    return (
                        <React.Fragment key={e.id}>
                            <Indicator item={e} />
                        </React.Fragment>
                    )
                })}
            </div>
        </div>
    )
}

export default HomeBoard;
