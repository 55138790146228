import { useState } from "react";
import "../styles/genericPage.css";
import "../styles/education-achievement.css";

import AchievementScores from "charts/education/AchievementScores";

export default function EducationAchievement() {

  const [filters, setFilters] = useState({
    contentarea: ["ELA"],
    grade: "All Students"
  });

  const toggleGrade = (event) => {
    setFilters({...filters, grade: event.target.value})
  }

  const toggleCharts = (event) => {
    const subjectsCopy = [...filters.contentarea]

    if (!subjectsCopy.includes(event.target.value)) {
      subjectsCopy.push(event.target.value)
      subjectsCopy.sort((a, b) => a.localeCompare(b))
      setFilters({ ...filters, contentarea: subjectsCopy })
    }
    else {
      const filteredSubjects = subjectsCopy.filter(item => item !== event.target.value)
      filteredSubjects.sort((a, b) => a.localeCompare(b))
      setFilters({ ...filters, contentarea: filteredSubjects })
    }
  }

  return (
    <>
      <div className="d-flex">
        <div className="header-container container">
          <h2 className="big-font">Achievement Scores</h2>
          <div className="interaction-container container">
            <div className="buttons-container">
              <button className={"toggle-button " + (filters.contentarea.includes("ELA") ? "active" : "not-active")} value="ELA" onClick={(event) => toggleCharts(event)}>English</button>
              <button className={"toggle-button " + (filters.contentarea.includes("MATH") ? "active" : "not-active")} value="MATH" onClick={(event) => toggleCharts(event)}>Math</button>
              <button className={"toggle-button " + (filters.contentarea.includes("SCI") ? "active" : "not-active")} value="SCI" onClick={(event) => toggleCharts(event)}>Science</button>
              <button className={"toggle-button " + (filters.contentarea.includes("SOC") ? "active" : "not-active")} value="SOC" onClick={(event) => toggleCharts(event)}>Social Studies</button>
            </div>
            <div className="dropdown-container">
              <label className="label" htmlFor="grades">
                Choose a grade:
              </label>
              <div className="select">
                <select
                  id="grades"
                  onChange={(event) => toggleGrade(event)}
                >
                  <option value="All Students">All Students</option>
                  <option value="3rd Grade">3rd Grade</option>
                  <option value="4th Grade">4th Grade</option>
                  <option value="5th Grade">5th Grade</option>
                  <option value="6th Grade">6th Grade</option>
                  <option value="7th Grade">7th Grade</option>
                  <option value="8th Grade">8th Grade</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      {filters.contentarea.length > 0 ? (
        <div className="container">
          <div className="gap-class"> <AchievementScores filters={filters}/></div>
        </div>
      ) : (
        <div className="container">
          <div className="text-center m-5 p-5">
            <h3>Select a subject area to view student performance</h3>
          </div>
        </div>    
      )}
    </>
  );
}
