import { Chart } from "charts/templates/Chart";
import { csv, autoType } from "d3";
import { useState, useEffect } from "react";
import data from 'data/safety/safety_concerns.csv';

export function SafetyConcernsBar() { 

const [chartData, setChartData] = useState([]);
useEffect(() => {
    csv(data, autoType).then((d) => {
    // Get safety questions only 
    setChartData(d.filter(d => d.question.indexOf("safety_concerns") > -1));
    });
}, []);



const series = [{
        type: "bar",
        color: "var(--green)",
        data: chartData.map(d => d.prop).sort((a,b) => b-a)
    }]
    

const options = {
    inverted: true,
    title: {
      text: "What are your top three safety concerns in Northeast Wilmington?",
    },
    caption: {
      text: "Source: 2020 Resident Survey",
      useHTML: true,
    },
    legend: {enabled: false},
    tooltip: {
        formatter: function() { 
         
            return `${(this.y * 100).toFixed(1)}% of respondents rated ${this.x.toLowerCase()} as one of their top three safety concerns.`
        }
    },
    series: series,
    xAxis: {
      categories: chartData.sort((a,b) => b.prop - a.prop).map(d => d.var_label)
    },
    yAxis: {
     tickInterval: .2,
     title: { text: undefined }, 
     labels: {
        formatter: function() { return (this.value * 100).toFixed(0)+"%"}
     }
    }
  };


  return (
    <div>
      {chartData === undefined ? (
        <strong>Loading</strong>
      ) : (
        <Chart options={{ ...options }} />
      )}
    </div>
  );
}