import { Chart } from "../templates/Chart";
import { csv, autoType } from "d3";
import { useState, useEffect } from "react";
import data from 'data/safety/community_safety.csv';

export const SafetyStackedPercentColumn = function () {
  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    csv(data, autoType).then((d) => {
      setChartData(d);
    });
  }, []);

  const chartDataNoNA = chartData.filter(item => item.response !== "NA"); 
  const pctDenom = chartDataNoNA.map(item => item.n).reduce((a,b) =>  a+b, 0); 


  const series = 
  chartDataNoNA.map(row => {
    return  {
        type: "bar",
        name: row.response,
        data: [row.n],
        dataLabels: {
          enabled: true,
          align: "center",
          useHTML: true,
          style: {  fontSize: "1rem", color:"white", textAlign: "center" }, 
          formatter: function() {
            
            return `${this.series.name.replace(" ","<br/>")}: <br/> ${((this.y / pctDenom) * 100).toFixed(1)}%`
          }
        }
      }
  })

  const options = {
    inverted: true,
    sequential: true,
    title: {
      text: "How would you rate the safety in the community?",
      style: { fontSize: "1.5rem" }
    },
    caption: {
      text: "Source: 2020 Resident Survey",
      useHTML: true,
    },
    series: series,
    legend: {
      enabled: false
    }, 
    tooltip: {
      formatter: function() { 
          return `${((this.percentage)).toFixed(1)}% of participants reported the safety of the community as ${this.series.name.toLowerCase()}.`
      }
    },
    xAxis: {
      visible: false
    },
    yAxis: {
      visible: false,
      reversed: true, 
      reversedStacks: true
    },
    plotOptions: {
      bar: {
          stacking: "percent"
      }
  },
  colors: ["#0C8CCD","#0F6498","#123C63","#09705B","#00A454"]
  };

  return (
    <div>
      {chartData === undefined ? (
        <strong>Loading</strong>
      ) : (
        <Chart options={{ ...options }} />
      )}
    </div>
  );
};
