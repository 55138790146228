import { Line } from "charts/templates/Line";
import data from "data/housing/pct_occupied.csv";


export default function OccupiedUnits () {
    
    const caption = "Source: <a href='https://www.huduser.gov/portal/datasets/assthsg.html' target='_blank'>HUD</a>";

    const tooltipOptions = function() {
        const tooltip = 
        this.series.name === "Delaware" 
        ?  `In ${this.x}, ${this.y.toFixed(1)}% of rental units were occupied in ${this.series.name} (${this.point.number_reported_Delaware} out of ${this.point.total_units_Delaware} units)` 
        : `In ${this.x}, ${this.y.toFixed(1)}% of rental units were occupied in ${this.series.name} (${this.point.number_reported_Riverside} out of ${this.point.total_units_Riverside} units)` 
        return tooltip; 
    }

    const moreOptions = {
        yAxis: {
            labels: {
              formatter: function () {
                return (this.value) + "%";
              }
            },
            title: {text: undefined},
          },
    }

    return (
        <Line
            data={data}
            title="Rental Units Occupied (%)"
            caption={caption}
            columns={[
                { colName: "pct_occupied_Delaware", name: "Delaware", color: "var(--gray)" },
                { colName: "pct_occupied_Riverside", name: "Riverside", color: "var(--green)" },
            ]}
            xAxisColumn="year" 
            tooltipOptions={tooltipOptions}
            moreOptions={moreOptions}
            moreCols={["total_units_Delaware","total_units_Riverside","pct_occupied_Delaware","pct_occupied_Riverside","number_reported_Delaware","number_reported_Riverside"]}
        />
    )
}