import { ReactComponent as HousingIcon } from "assets/icons/housing.svg";
import { ReactComponent as EducationIcon } from "assets/icons/education.svg";
import { ReactComponent as EventsIcon } from "assets/icons/events.svg";
import { ReactComponent as SafetyIcon } from "assets/icons/security.svg";
import { ReactComponent as WorkforceIcon } from "assets/icons/briefcase.svg";
import AvailableUnits from "charts/housing/availableUnits";
import OccupiedUnits from "charts/housing/occupiedUnits";
import MovedIn from "charts/housing/movedIn"



export const indicatorData = [
    {
        id: 0,
        title: "Housing",
        icon: <HousingIcon className="indicator-icon" />,
        content: [
            {
                statsID: 0,
                statsCaption: "Change in available units for rent in Riverside per year",
                statsValue: "- 4.3",
                statsUnit: "units",
                statsValueColor: 0,
                statsURL: "/housing/rent",
                title: "Available Units For Rent",
                chart: <AvailableUnits />
            },
            {
                statsID: 1,
                statsCaption: "Average change in proportion of occupied units per year",
                statsValue: "- 3.9",
                statsUnit: "%",
                statsValueColor: 0,
                statsURL: "/housing/occupied",
                title: "Occupied Units",
                chart: <OccupiedUnits />
            },
            {
                statsID: 2,
                statsCaption: "Average yearly increase in months since moved into public housing",
                statsValue: "+ 3.6",
                statsUnit: "months",
                statsValueColor: 1,
                statsURL: "/housing/moved-in",
                title: "Average Months Since Moved In",
                chart: <MovedIn />
            }
        ]
    },
    {
        id: 1,
        title: "Education",
        icon: <EducationIcon className="indicator-icon" />,
        content: [
            {
                statsID: 0,
                statsCaption: "Improvement in 4-year high school graduation gap to Delaware per year",
                statsValue: "+ 0.5",
                statsUnit: "%",
                statsValueColor: 1,
                statsURL: "/education/graduation-gap"
            },
            {
                statsID: 1,
                statsCaption: "More kindergarten-ready than Delaware average",
                statsValue: "+ 21.9",
                statsUnit: "%",
                statsValueColor: 1,
                statsURL: "/education/kindergarten"
            },
            {
                statsID: 2,
                statsCaption: "Improvement in literacy achievement gap compared to Delaware per year",
                statsValue: "+ 3.0",
                statsUnit: "%",
                statsValueColor: 1,
                statsURL: "/education/literacy"
            },
            {
                statsID: 3,
                statsCaption: "Improvement in math achievement gap to Delaware per year",
                statsValue: "+ 2.1",
                statsUnit: "%",
                statsValueColor: 1,
                statsURL: "/education/math"
            }
            
        ]

    },
    {
        id: 2,
        title: "Workforce",
        icon: <WorkforceIcon className="indicator-icon" />,
        content: [
            {
                statsID: 0,
                statsCaption: "Change in employment rate in WRK Group's cencus tracts compared to Wilmington since 2017",
                statsValue: "- 2.7",
                statsUnit: "%",
                statsValueColor: 0,
                statsURL: "/workforce/employment-comparisons"
            }
        ]
    },
    {
        id: 3,
        title: "Safety",
        icon: <SafetyIcon className="indicator-icon" />,
        content: [
            {
                statsID: 0,
                statsValue: "66.8",
                statsUnit: "%",
                statsCaption: "Survey reported feeling safe while walking during the day",
                statsValueColor: 2,
                statsURL: "/safety/walking",
            },
            {
                statsID: 1,
                statsValue: "32.8",
                statsUnit: "%",
                statsCaption: "Survey reported feeling safe while walking at night",
                statsValueColor: 2,
                statsURL: "/safety/walking",
            }
        ]
    },
    {
        id: 4,
        title: "Events",
        icon: <EventsIcon className="indicator-icon" />,
        content: [
            {
                statsID: 0,
                statsValue: "1824.6",
                statsCaption: "Average hours of service provided to the community per year",
                statsUnit: "hours",
                statsValueColor: 2,
                statsURL: "/events/hours",
            },
            {
                statsID: 1,
                statsValue: "86.4",
                statsCaption: "Average events hosted per month at The Warehouse",
                statsUnit: "events",
                statsValueColor: 2,
                statsURL: "/events/counts",
            }
            
        ]
    }
]