const housingData = [
    {
      name: "Available Units for Rent",
      url: "rent"
    },
    {
      name: "Occupied Units",
      url: "occupied"
    },
    {
      name: "Average Months Since Moved In",
      url: "moved-in"
    },
    // {
    //   name: "Housing Details",
    //   url: "housing-details"
    // },
  ]

housingData.sort((a, b) => a.name.localeCompare(b.name))


  export default housingData;