import { csv, autoType } from "d3";
import { useState, useEffect } from "react";
import { Chart } from "../templates/Chart";
import { tidy, complete } from "@tidyjs/tidy";

import data from "data/events/top_events.csv";

export const TopEventsStackedColumn = function () {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    csv(data, autoType).then((d) => {
      setChartData(d);
    });
  }, []);

  const completedData = tidy(
    chartData,
    complete(["year", "top_events"], { sum_hour_rounded: 0 })
  ).sort((a, b) => a.year - b.year);

  const years = [2020, 2021, 2022];

  const columns = [
    ...Array.from(new Set(chartData.map((i) => i.top_events))).filter(
      (column) => column !== "Other"
    ),
    "Other",
  ];

  const series = columns.map((column) => {
    return {
      type: "column",
      name: column,
      data: completedData
        .filter((d, i) => d.top_events === column)
        .map((d, i) => {
          return {
            y: d.sum_hour_rounded,
            year: d.year,
            event: d.top_events,
          };
        }),
    };
  });

  const tooltipOptions = function () {
    return `In ${this.key}, The Warehouse spent ${this.y} for the event <em>${this.point.event}</em>`;
  };

  const options = {
    title: {
      text: "Events with Most Hours",
    },
    caption: {
      text: "Source: <a href='https://thewarehouse.recdesk.com/Community/Calendar' target='_blank'>The Warehouse Calendar</a>",
      useHTML: true,
    },
    tooltip: {
      useHTML: true,
      formatter: tooltipOptions,
    },
    plotOptions: {
      column: {
        stacking: "normal",
      },
    },
    series: series,
    legend: {
      enabled: "legendEnabled",
      verticalAlign: "middle",
      align: "right",
      layout: "vertical",
      itemMarginTop: 10,
      itemMarginBottom: 10,
      title: {
        style: { fontSize: "1.3rem" },
        text: "Top Events",
      },
    },
    xAxis: {
      categories: years,
    },
    yAxis: {
      min: 0,
      title: {
        text: undefined,
      },
    },
  };

  return (
    <div>
      {chartData === undefined ? "Loading" : <Chart options={{ ...options }} />}
    </div>
  );
};
