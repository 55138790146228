import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "styles/genericPage.css";

import GraduationGap from "charts/education/GraduationGap";
import KindergartenReadiness from "charts/education/KindergartenReadiness";
import LiteracyAchievement from "charts/education/LiteracyAchievement";
import MathAchievement from "charts/education/MathAchievement";

function Education() {

  const [educationData, setEducationData] = useState([]);
  const { indicator } = useParams();

  useEffect(() => {
    switch (indicator) {

        case "graduation-gap":
        setEducationData([
        {
            title: "Graduation Gap",
            indicator: "+0.5% per year",
            value: "Improvement in 4-year high school graduation gap to Delaware, since 2016",
            notes: [
            "On average, the gaps in the graduation rate with the state average worsened by 0.11% per year from 2015 to 2020.",
            "However, if we focus on 2016 onwards, the gap may be improving. On average, the gap improved by 0.46% per year from 2016 to 2020.",
            ],
            chart: <GraduationGap />

        },
        ]);
        break;

        case "kindergarten":
        setEducationData([
        {
            title: "Kindergarten Readiness",
            indicator: "21.9%",
            value: "More kindergarten-ready than Delaware average",
            notes: [
            "On average, 77.3% of children in WRK group were kindergarten ready (2019-2021), compared to 63.4% of children in Delaware (2016-2019).",
            "The rate of kindergarten readiness in WRK group is 21.9% more than that of Delaware.",
            ],
            chart: <KindergartenReadiness/>
        },
        ]);
        break;

      case "literacy":
        setEducationData([
          {
            title: "Literacy Achievement",
            indicator: "+3.0% per year",
            value: "Improvement in literacy achievement gap to Delaware",
            notes: [
              "In 2021, 30.6% of the 3rd-8th graders at East Side Charter in Riverside achieved the literacy proficiency.",
              "The gap to the Delaware average 40.7% was -10%. The gap has been improving by 3% a year.",
            ],
            chart: <LiteracyAchievement />
          },
        ]);
        break;

      case "math":
        setEducationData([
          {
            title: "Math Achievement",
            indicator: "+2.1% per year",
            value: "Improvement in math achievement gap to Delaware",
            notes: [
              "In 2021, 23.8% of the 3rd-8th graders at East Side Charter in Riverside achieved the math proficiency.",
              "The gap to the Delaware average (26.2%) was -2.4%. The gap has been improving by 2.1% a year.",
            ],
            chart: <MathAchievement />
          },
        ]);
        break;

        default:
            setEducationData([])
            break;
    }
  }, [indicator]);

  return (
    <>
      {educationData.map((item) => (
        <div className="flex" key={item.title}>
          <div className="chart-container">
            <div className="header">
              <h2 className="m-0 big-font">{item.title}</h2>
            </div>
            <div className="gap-class">
              {item.chart}
            </div>
          </div>
          {item.value ? (
          <div className="notes-container">
            <div className="value-box gap-class">
              <h3 className="big-font">{item.indicator}</h3>
              <h4>{item.value}</h4>
            </div>
          
            {item.notes ? (
            <div className="notes gap-class">
              <h3 className="text-center big-font">Notes</h3>
              <hr></hr>
              <ul>
                {item.notes.map((note, i) => (
                  <li key={i}>
                    <h4>{note}</h4>
                    <br></br>
                  </li>
                ))}
              </ul> 
            </div>) : ("")}
          </div>) : ("")}
        </div>
      ))}
    </>
  );
}

export default Education;
