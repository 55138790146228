const eventsData = [
    {
      name: "Number of Events",
      url: "counts"
    },
    {
      name: "Duration of Events",
      url: "hours"
    },
    {
      name: "Top Events",
      url: "top"
    }
  ]

eventsData.sort((a, b) => a.name.localeCompare(b.name))

  export default eventsData;